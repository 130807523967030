import React, { useState } from 'react';
import { connect } from 'react-redux';
import { appendIndex, removeFromCalendar } from '../actions/auth';

const Schedule = ({ CalendarIndexes, SniperIndexes, appendIndex, removeFromCalendar }) => {
  const [expandedSlot, setExpandedSlot] = useState(null);
  const [hoveredBlock, setHoveredBlock] = useState(null);

  // Define time slots from 8 AM to 10 PM
  const timeSlots = [];
  for (let hour = 8; hour <= 22; hour++) {
    timeSlots.push(`${hour % 12 || 12}:00 ${hour < 12 ? 'AM' : 'PM'}`);
  }

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const timeToDecimal = (timeStr) => {
    const [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    return hours + (minutes / 60);
  };

  const calculateBlockHeight = (startTime, endTime) => {
    const start = timeToDecimal(startTime);
    const end = timeToDecimal(endTime);
    const duration = end - start;
    return `${duration * 60}px`; // Each hour is 60px
  };

  const calculateTopOffset = (startTime, slotTime) => {
    const start = timeToDecimal(startTime);
    const slot = Math.floor(timeToDecimal(slotTime));
    const offset = (start - slot) * 60;
    return `${offset}px`;
  };

  const isBeingSniped = (courseIndex) => {
    return SniperIndexes.some(item => item.index === courseIndex);
  };

  const isInBothLists = (courseIndex) => {
    const inSniper = SniperIndexes.some(item => item.sectionIndex === courseIndex);
    const inCalendar = CalendarIndexes.some(course => course.sectionIndex === courseIndex);
    return inSniper && inCalendar;
  };

  const handleSnipeClick = (e, courseIndex) => {
    e.stopPropagation();
    appendIndex([courseIndex]);
  };

  const handleRemove = (e, sectionIndex) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('Removing course with index:', sectionIndex);
    removeFromCalendar(sectionIndex);
  };

  const handleSlotMouseEnter = (slotId) => {
    setExpandedSlot(slotId);
  };

  const handleSlotMouseLeave = () => {
    if (!hoveredBlock) {
      setExpandedSlot(null);
    }
  };

  const handleBlockMouseEnter = (slotId, blockId) => {
    setExpandedSlot(slotId);
    setHoveredBlock(blockId);
  };

  const handleBlockMouseLeave = () => {
    setHoveredBlock(null);
  };

  return (
    <div className="overflow-x-auto bg-gray-800 rounded-lg shadow-lg p-2 md:p-4">
      <div className="min-w-[500px] md:min-w-[600px]">
        {/* Header */}
        <div className="grid grid-cols-[80px_repeat(5,1fr)] mb-2">
          <div className="text-gray-400 text-sm font-semibold">Time</div>
          {days.map(day => (
            <div key={day} className="text-white font-bold text-center text-sm">
              {day}
            </div>
          ))}
        </div>

        {/* Schedule Grid */}
        <div className="grid grid-cols-[80px_repeat(5,1fr)]">
          {timeSlots.map((timeSlot) => (
            <React.Fragment key={timeSlot}>
              <div className="text-gray-400 text-xs py-2 pr-2 text-right h-[60px] border-t border-gray-700">
                {timeSlot}
              </div>
              {days.map((day, dayIndex) => {
                // Get all courses for this day and time slot
                const coursesInSlot = CalendarIndexes.filter(course => {
                  const meeting = course.meetingTimes.find(m => m.days === day);
                  if (!meeting) return false;
                  const startHour = Math.floor(timeToDecimal(meeting.startTime));
                  const slotHour = Math.floor(timeToDecimal(timeSlot));
                  return startHour === slotHour;
                });

                const hasOverlap = coursesInSlot.length > 1;
                const slotId = `${day}-${timeSlot}`;
                const isExpanded = expandedSlot === slotId;

                return (
                  <div 
                    key={slotId}
                    className={`border-t border-gray-700 relative ${isExpanded ? 'h-auto' : 'h-[60px]'}`}
                    onMouseEnter={() => hasOverlap && handleSlotMouseEnter(slotId)}
                    onMouseLeave={handleSlotMouseLeave}
                  >
                    <div className={`absolute w-full ${isExpanded ? 'h-auto' : ''}`}>
                      <div className={`relative ${isExpanded ? 'space-y-2' : ''}`}>
                        {coursesInSlot.map((course, idx) => {
                          const meeting = course.meetingTimes.find(m => m.days === day);
                          const isSnipedClass = isBeingSniped(course.sectionIndex);
                          const isInBoth = isInBothLists(course.sectionIndex);
                          const blockHeight = calculateBlockHeight(meeting.startTime, meeting.endTime);
                          const heightInPixels = parseFloat(blockHeight);
                          const fontSize = heightInPixels < 45 ? '0.65rem' : '0.75rem';
                          const tooltipPosition = dayIndex <= 1 ? 'right' : 'left';
                          const blockId = `${slotId}-${course.sectionIndex}`;
                          const isHovered = hoveredBlock === blockId;

                          return (
                            <div
                              key={`${course.sectionIndex}-${idx}`}
                              className={`w-[95%] mx-auto transition-all duration-300 ease-in-out
                                ${!isExpanded && hasOverlap ? 'absolute' : 'relative'}
                                ${!isExpanded && hasOverlap && !isInBoth ? `opacity-${100 - (idx * 20)}` : ''}`}
                              style={{
                                top: !isExpanded && hasOverlap ? calculateTopOffset(meeting.startTime, timeSlot) : 'auto',
                                zIndex: isHovered ? 60 : hasOverlap ? coursesInSlot.length - idx : 1,
                              }}
                              onMouseEnter={() => handleBlockMouseEnter(slotId, blockId)}
                              onMouseLeave={handleBlockMouseLeave}
                            >
                              <div
                                className={`rounded-md shadow-md overflow-hidden
                                  ${isInBoth ? 'bg-green-600 text-white' : 
                                    isSnipedClass ? 'bg-blue-600 text-white' : 'text-gray-800'}
                                  transition-all duration-200 hover:scale-[1.02]`}
                                style={{
                                  height: blockHeight,
                                  backgroundColor: isInBoth ? undefined : 
                                    isSnipedClass ? undefined : '#f0f0f0',
                                }}
                              >
                                <div className="p-1 h-full flex flex-col justify-between">
                                  <div style={{ fontSize }}>
                                    <div className="font-bold leading-tight">{course.courseTitle}</div>
                                    <div className="leading-tight">{course.professor}</div>
                                    <div className="leading-tight text-[0.65rem]">
                                      {`${meeting.startTime} - ${meeting.endTime}`}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Tooltip */}
                              {isHovered && (
                                <div className={`absolute 
                                  ${tooltipPosition === 'right' 
                                    ? 'left-full ml-2' 
                                    : 'right-full mr-2'} 
                                  top-0 bg-gray-900 text-white rounded-lg shadow-xl p-4 z-[70] w-80 
                                  border border-gray-700`}
                                >
                                  <div className={`absolute top-4 
                                    ${tooltipPosition === 'right'
                                      ? 'right-full border-r-gray-900'
                                      : 'left-full border-l-gray-900'}
                                    border-8 border-transparent`}
                                  />
                                  
                                  <div className="space-y-2">
                                    <div className="flex justify-between items-start">
                                      <h4 className="font-bold text-lg">{course.courseTitle}</h4>
                                      <button
                                        onClick={(e) => handleRemove(e, course.sectionIndex)}
                                        className="text-red-500 hover:text-red-400 p-1 rounded-full 
                                          hover:bg-red-500/10 transition-colors"
                                        type="button"
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" 
                                          className="h-5 w-5" 
                                          viewBox="0 0 20 20" 
                                          fill="currentColor"
                                        >
                                          <path fillRule="evenodd" 
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" 
                                            clipRule="evenodd" 
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="space-y-1 text-sm">
                                      <p><span className="font-semibold">Professor:</span> {course.professor}</p>
                                      <p><span className="font-semibold">Section Number:</span> {course.sectionNumber}</p>
                                      <p><span className="font-semibold">Index:</span> {course.sectionIndex}</p>
                                      <p><span className="font-semibold">Time:</span> {`${meeting.startTime} - ${meeting.endTime}`}</p>
                                      <p><span className="font-semibold">Day:</span> {meeting.days}</p>
                                    </div>
                                    
                                    {!isSnipedClass ? (
                                      <button
                                        onClick={(e) => handleSnipeClick(e, course.sectionIndex)}
                                        className="mt-2 w-full bg-blue-600 text-white px-4 py-2 rounded-md 
                                          hover:bg-blue-700 transition-colors text-sm font-semibold"
                                      >
                                        Add to Snipe List
                                      </button>
                                    ) : (
                                      <div className="mt-2 bg-green-600 text-white px-4 py-2 rounded-md 
                                        text-center text-sm font-semibold">
                                        Currently Sniping
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  CalendarIndexes: state.index.CalendarIndexes,
  SniperIndexes: state.index.SniperIndexes
});

export default connect(mapStateToProps, { appendIndex, removeFromCalendar })(Schedule);
