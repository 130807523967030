// // types.js
export const SET_USERNAME = 'SET_USERNAME';
export const SET_YEAR = 'SET_YEAR';
export const SET_TELEGRAM = 'SET_TELEGRAM'; // Correct spelling
export const SET_PHONE = 'SET_PHONE'; // Correct spelling
export const SET_MEMBERSHIP = 'SET_MEMBERSHIP';
export const SET_EMAIL = 'AUTHENTICATED_FAIL';
export const SET_DISCORD = 'LOGOUT';
export const APPEND_INDEX = 'APPEND_INDEX';
export const REMOVE_INDEX = 'REMOVE_INDEX';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL= 'AUTHENTICATED_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGIN_PROFILE = 'LOGIN_PROFILE';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const LOGOUT_PROFILE = 'LOGOUT_PROFILE';
export const RESET_STATE = 'RESET_STATE';
export const ADD_TO_CALENDAR = 'ADD_TO_CALENDAR';
export const REMOVE_FROM_CALENDAR = 'REMOVE_FROM_CALENDAR';
export const CALENDAR_CONFLICT_ERROR = 'CALENDAR_CONFLICT_ERROR';
export const CLEAR_CALENDAR_ERROR = 'CLEAR_CALENDAR_ERROR';






