import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../index.css';
import { appendToCalendar } from '../actions/auth';
import { connect } from 'react-redux';

const SearchResults = ({appendToCalendar}) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedCourses, setExpandedCourses] = useState({});
  const [openSections, setOpenSections] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all'); // Filter state
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const title = query.get('title');

  const dayMap = {
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    H: 'Thursday',
    F: 'Friday',
    S: 'Saturday',
    U: 'Sunday',
  };

  const parseSections = (sections) => {
    try {
      const cleanedSections = sections
        .replace(/'/g, '"') // Convert single quotes to double quotes for JSON parsing
        .replace(/\b(\w+)\s*:/g, '"$1":'); // Add quotes around keys
      return JSON.parse(cleanedSections);
    } catch (parseError) {
      console.error('Failed to parse sections:', parseError);
      return [];
    }
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    let hours = parseInt(time.slice(0, 2), 10);
    const minutes = time.slice(2);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getFullDayName = (abbreviation) => {
    return abbreviation
      .split('')
      .map((day) => dayMap[day] || day)
      .join(', ');
  };

  const handleAddSection = (course, section) => {

    console.log('handleAddSection called with:', { course, section });

    const formattedTimes = section.meetingTimes.map((time) => ({
      days: getFullDayName(time.Meetingdays) || 'N/A',
      startTime: time.StartTime ? formatTime(time.StartTime) : 'N/A',
      endTime: time.EndTime ? formatTime(time.EndTime) : 'N/A',
    }));

    console.log('Add button clicked for section:', {
      courseName: course.title,
      courseNumber: course.courseString,
      sectionNumber: section.number,
      sectionIndex: section.index,
      professor: section.instructorsText,
      meetingTimes: formattedTimes,
    });

    appendToCalendar({courseTitle: course.title, courseNumber: course.number, sectionNumber: section.number,sectionIndex: section.index,professor: section.instructorsText,meetingTimes: formattedTimes})
    alert(
      `Added Course: ${course.title}  ${course.courseString}\nSection: ${section.number}\nProfessor: ${section.instructorsText}`
    );

    // Implement additional logic for handling the added section
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (!title) return;
      try {
        const response = await axios.get(
          `https://api.ruturbo.com/search?title=${encodeURIComponent(title)}`
        );
        setResults(response.data.results);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    const fetchOpenSections = async () => {
      try {
        const response = await axios.get(
          'https://api.ruturbo.com/proxy/openSections'
        );
        setOpenSections(response.data); // List of open section indices
      } catch (err) {
        console.error('Error fetching open sections:', err);
      }
    };

    fetchResults();
    fetchOpenSections();
  }, [title]);

  const toggleCourse = (index) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const isSectionOpen = (index) => {
    return openSections.includes(index) ? 'open' : 'closed';
  };

  const getOpenSectionsCount = (sections) => {
    const parsedSections = parseSections(sections);
    return parsedSections.filter((section) =>
      openSections.includes(section.index)
    ).length;
  };

  const filteredSections = (sections) => {
    const parsedSections = parseSections(sections);

    if (statusFilter === 'all') return parsedSections;

    return parsedSections.filter((section) =>
      statusFilter === 'open'
        ? openSections.includes(section.index)
        : !openSections.includes(section.index)
    );
  };

  if (loading) return <p style={{ color: 'white' }}>Loading...</p>;
  if (error) return <p style={{ color: 'white' }}>{error}</p>;

  return (
    <div className="search-results">
      <h2 style={{ color: 'white' }}>Search Results for "{title}"</h2>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="status-filter" style={{ color: 'white', marginRight: '10px' }}>
          Filter by status:
        </label>
        <select
          id="status-filter"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="all">All</option>
          <option value="open">Open</option>
          <option value="closed">Closed</option>
        </select>
      </div>
      {results.length > 0 ? (
        <ul className="course-list">
          {results.map((result, index) => {
            const parsedSections = filteredSections(result.sections);

            return (
              <li key={index} className="course-item">
                <div className="course-header" onClick={() => toggleCourse(index)}>
                  <div className="course-info">
                    <span className="course-code">{result.courseString}</span>
                    <span className="course-title">{result.title}</span>
                    <span className="course-credits">{result.credits} cr.</span>
                    <span className="course-status">
                      Sections: {parsedSections.length}
                    </span>
                  </div>
                  <button className="expand-toggle">
                    {expandedCourses[index] ? '-' : '+'}
                  </button>
                </div>

                {expandedCourses[index] && (
                  <div className="course-details">
                    <h4 style={{ color: 'white' }}>Sections:</h4>
                    {parsedSections.map((section, secIndex) => (
                      <div key={secIndex} className="section-item">
                        <div className="section-header">
                          <span className="section-number">
                            Section {section.number} (Index: {section.index})
                          </span>
                          <span
                            className={`section-status ${isSectionOpen(
                              section.index
                            )}`}
                          >
                            {isSectionOpen(section.index)}
                          </span>
                        </div>
                        <div
                          className="professor"
                          style={{ color: 'white', marginBottom: '8px' }}
                        >
                          <strong>Professor:</strong> {section.instructorsText || 'N/A'}
                        </div>
                        <div className="meeting-times">
                          <table className="meeting-table">
                            <thead>
                              <tr>
                                <th>Day</th>
                                <th>Time</th>
                                <th>Location</th>
                                <th>Campus</th>
                              </tr>
                            </thead>
                            <tbody>
                              {section.meetingTimes.map((time, timeIndex) => (
                                <tr key={timeIndex}>
                                  <td>{getFullDayName(time.Meetingdays) || 'N/A'}</td>
                                  <td>
                                    {time.StartTime
                                      ? `${formatTime(time.StartTime)} - ${formatTime(
                                          time.EndTime
                                        )}`
                                      : 'N/A'}
                                  </td>
                                  <td>
                                    {time.buildingCode && time.roomnumber
                                      ? `${time.buildingCode} ${time.roomnumber}`
                                      : 'N/A'}
                                  </td>
                                  <td>{time.Campus || 'N/A'}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="section-actions" style={{ marginTop: '10px' }}>
                          <button
                            onClick={() => handleAddSection(result, section)}
                            className="add-button"
                            style={{
                              color: 'white',
                              background: 'blue',
                              padding: '5px 10px',
                            }}
                          >
                            Add Section
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <p style={{ color: 'white' }}>No results found.</p>
      )}
    </div>
  );
};


const mapStateToProps = (state) => ({
  CalendarIndexes: state.index.CalendarIndexes
});

export default connect(mapStateToProps, {appendToCalendar})(SearchResults);
