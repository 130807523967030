import {
    APPEND_INDEX,
    REMOVE_INDEX,
    REMOVE_FROM_CALENDAR,
    ADD_TO_CALENDAR,
  } from '../actions/types';
  
  const initialState = {
    SniperIndexes: [],
    CalendarIndexes: [],
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case APPEND_INDEX:
        return {
          ...state,
          SniperIndexes: [...state.SniperIndexes, payload],
        };
  
      case REMOVE_INDEX:
        return {
          ...state,
          SniperIndexes: state.SniperIndexes.filter(
            (obj) => obj.index !== payload
          ),
        };
  
      case ADD_TO_CALENDAR:
        return {
          ...state,
          CalendarIndexes: [...state.CalendarIndexes, payload],
        };
  
      case REMOVE_FROM_CALENDAR:
        return {
          ...state,
          CalendarIndexes: state.CalendarIndexes.filter(
            (course) => course.sectionIndex !== payload
          ),
        };
  
      default:
        return state;
    }
  }
  