import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { appendIndex, removeIndex, threads } from '../actions/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Schedule from '../components/Schedule';

const Dashboard = ({ SniperIndexes, appendIndex, removeIndex, threads }) => {
  const [courseSearchValue, setCourseSearchValue] = useState('');
  const [numberInputValue, setNumberInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [threadsData, setThreadsData] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchThreads = async () => {
      const data = await threads();
      setThreadsData(data);
    };
    fetchThreads();
  }, [threads]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (courseSearchValue.trim() === '') {
        setSuggestions([]);
        return;
      }
      try {
        const response = await axios.get(
          `https://api.ruturbo.com/autocomplete?title=${encodeURIComponent(courseSearchValue)}`
        );
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };
    fetchSuggestions();
  }, [courseSearchValue]);

  const handleCourseSearchChange = (e) => {
    setCourseSearchValue(e.target.value);
  };

  const handleNumberInputChange = (e) => {
    setNumberInputValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setCourseSearchValue(suggestion);
    setSuggestions([]);
    navigate(`/search?title=${encodeURIComponent(suggestion)}`);
  };

  const handleCourseSearchSubmit = (e) => {
    e.preventDefault();
    if (courseSearchValue.trim() === '') return;
    navigate(`/search?title=${encodeURIComponent(courseSearchValue)}`);
  };

  const handleNumberSubmit = (e) => {
    e.preventDefault();
    const numArray = numberInputValue.split(',').map((num) => num.trim());
    const isValidNumList = numArray.every((num) => /^\d{5}$/.test(num));

    if (!isValidNumList) {
      alert('Please enter valid 5-digit numbers separated by commas.');
      return;
    }

    appendIndex(numArray);
    setNumberInputValue('');
  };

  const handleDelete = (indexToDelete) => {
    removeIndex(indexToDelete);
  };

  const toggleExpand = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const formatTime = (time) => {
    return time;
  };

  return (
    <div className="p-4 md:p-6">
      <h2 className="text-white text-2xl md:text-3xl font-bold mb-4 md:mb-8">Dashboard</h2>

      <div className="flex flex-col lg:flex-row gap-4 md:gap-8">
        <div className="w-full lg:w-1/2">
          <div className="sticky top-0">
            <h3 className="text-white text-lg md:text-xl font-bold mb-2 md:mb-4">Class Schedule</h3>
            <Schedule />
          </div>
        </div>

        <div className="w-full lg:w-1/2 space-y-4 md:space-y-6">
          <div className="bg-gray-800 p-3 md:p-4 rounded-lg">
            <h3 className="text-white text-base md:text-lg font-semibold mb-2 md:mb-3">
              Search Courses
            </h3>
            <form onSubmit={handleCourseSearchSubmit} className="space-y-2">
              <input
                type="text"
                placeholder="Search for courses"
                value={courseSearchValue}
                onChange={handleCourseSearchChange}
                className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:outline-none text-sm md:text-base"
              />
              <button type="submit" className="w-full md:w-auto bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                Search
              </button>
            </form>
            {suggestions.length > 0 && (
              <ul className="mt-2 bg-gray-700 rounded-lg overflow-hidden max-h-48 overflow-y-auto">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="px-4 py-2 hover:bg-gray-600 cursor-pointer text-white text-sm md:text-base"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="bg-gray-800 p-3 md:p-4 rounded-lg">
            <h3 className="text-white text-base md:text-lg font-semibold mb-2 md:mb-3">
              Add Course Numbers
            </h3>
            <form onSubmit={handleNumberSubmit} className="space-y-2">
              <input
                type="text"
                placeholder="Enter 5-digit numbers separated by commas"
                value={numberInputValue}
                onChange={handleNumberInputChange}
                className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:outline-none text-sm md:text-base"
              />
              <button type="submit" className="w-full md:w-auto bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
                Submit Numbers
              </button>
            </form>
          </div>

          <div className="bg-gray-800 p-3 md:p-4 rounded-lg">
            <h3 className="text-white text-base md:text-lg font-semibold mb-2 md:mb-3">
              Submitted Numbers
            </h3>
            <div className="space-y-3">
              {SniperIndexes.map((item, idx) => (
                <div key={idx} className="bg-gray-700 rounded-lg overflow-hidden">
                  <div className="p-4">
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex-1">
                        <h4 className="text-lg font-semibold text-white mb-1">
                          {item.courseTitle || 'Course Title'}
                        </h4>
                        <div className="text-gray-300 text-sm space-y-1">
                          <p>Professor: {item.professor || 'TBA'}</p>
                          <p>Section: {item.sectionNumber || 'N/A'}</p>
                          <p>Index: {item.sectionIndex}</p>
                        </div>
                      </div>
                      <button
                        onClick={() => handleDelete(item.index)}
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded text-sm transition-colors duration-200"
                      >
                        Delete
                      </button>
                    </div>
                    
                    <button
                      onClick={() => toggleExpand(item.index)}
                      className="w-full mt-2 bg-gray-600 hover:bg-gray-500 text-white px-4 py-2 rounded flex items-center justify-between transition-colors duration-200"
                    >
                      <span>Meeting Times</span>
                      <svg
                        className={`w-5 h-5 transform transition-transform duration-200 ${
                          expandedItems[item.index] ? 'rotate-180' : ''
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {expandedItems[item.index] && item.meetingTimes && (
                      <div className="mt-3 space-y-2">
                        {item.meetingTimes.map((meeting, mIdx) => (
                          <div key={mIdx} className="bg-gray-600 p-3 rounded-lg">
                            <div className="flex justify-between items-center">
                              <span className="text-white font-medium">{meeting.days}</span>
                              <div className="text-gray-300 text-sm">
                                {formatTime(meeting.startTime)} - {formatTime(meeting.endTime)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-800 p-3 md:p-4 rounded-lg">
            <h3 className="text-white text-base md:text-lg font-semibold mb-2 md:mb-3">
              Threads Data
            </h3>
            {threadsData !== null ? (
              <p className="text-white text-sm md:text-base">Threads: {threadsData}</p>
            ) : (
              <p className="text-gray-400 text-sm md:text-base">Loading threads data...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  SniperIndexes: state.index.SniperIndexes,
});

export default connect(mapStateToProps, { appendIndex, removeIndex, threads })(Dashboard);
